<template>
  <div>
    <!-- TODO: 未处理 key 问题 -->
    <!-- <SearchPanel
      :search-params="searchParamsObject"
      @update:searchParams="(val, key, index) => { searchParamsObject[index].value = val }"
      @button-event="searhPanelButtonEvent"
      :showMultipleSelect="true"
    /> -->

    <Search
      :search-params="searchParamsObject"
      @update:searchParams="(val, index, flag) => { 
        if (flag != undefined) {
          searchParamsObject[index].value[flag] = val
        } else {
          searchParamsObject[index].value = val
        }
      }"
      @button-event="searhPanelButtonEvent"
    />
    
    <b-card>
      <MainActionPanel
        :buttonLeftOptions="buttonLeftOptions"
        :buttonRightOptions="buttonRightOptions"
        @main-action="mainActionEvent"
      />
      
      <!-- table -->
      <vxe-table
        ref="xTable" border :auto-resize="true"
        :data="tableData" highlight-current-row
        :cell-class-name="cellClassName"
        :loading="loading" :scroll-x="{enabled: true}"
        :scroll-y="{enabled: true}"
      >
        <vxe-table-column field="id" title="ID" width="80"></vxe-table-column>
        <vxe-table-column field="name" title="产品名称" width="200" show-overflow></vxe-table-column>
        <vxe-table-column field="pic" title="产品图片" width="100" align="center">
          <template v-slot="{ row }">
            <viewer><b-img :src="row.pic" height="35" width="auto" class="d-inline-block" /></viewer>
          </template>
        </vxe-table-column>
        <vxe-table-column field="projectName" title="所属项目" width="200" show-overflow></vxe-table-column>
        <vxe-table-column field="address" title="地址" min-width="200" show-overflow></vxe-table-column>
        <vxe-table-column field="area" title="面积(㎡)" min-width="100"></vxe-table-column>
        <vxe-table-column field="floor" title="楼层" min-width="100"></vxe-table-column>
        <vxe-table-column field="price" title="价格" min-width="100"></vxe-table-column>
        <vxe-table-column field="type" title="类型" min-width="100"></vxe-table-column>
        <vxe-table-column field="sort" title="排序" width="100"></vxe-table-column>
        <vxe-table-column field="status" title="状态" width="120" align="center">
          <template v-slot="{ row }">
            <b-badge pill :variant="row.status ? 'light-primary' : 'light-warning'" class="px-1 py-25">
              {{ row.status ? '启用' : '停用' }}
            </b-badge>
          </template>
        </vxe-table-column>
        <vxe-table-column field="action" title="操作" align="center" width="160" fixed="right">
          <template v-slot="{ row }">
            <table-action-panel
              :rowName="row.id"
              :extraData="row"
              :menu="setMenuDisabled(row, 'status')" 
              @click-event="tableActionEvent"
            />
          </template>
        </vxe-table-column>
      </vxe-table>

      <page-set-panel
        :page.sync="searchParams.page"
        :size.sync="searchParams.size"
        :total="total"
      />
    </b-card>

    <SidebarPanel
      v-model="isShowSidebarActive"
      :blankEditParams="blankEditParams"
      :editValue="editValue"
      title="项目产品"
      @edit-event="editEvent"
      :layConfig="2"
    />
  </div>

</template>

<script>
// 查询参数配置组件
import TableActionPanel from '@/comps/TableActionPanel'
// 页码组件
import PageSetPanel from '@/comps/PageSetPanel'
// 导入接口函数
import useFunction from './useFunction'
// 操作区域控制
// import SearchPanel from '@/comps/SearchPanel'
import Search from '@/comps/Search'
// 操作区域控制
import MainActionPanel from '@/comps/MainActionPanel'
// 边栏模式编辑
import SidebarPanel from '@/comps/SidebarPanel'

import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    PageSetPanel,
    // SearchPanel,
    Search,
    TableActionPanel,
    MainActionPanel,
    SidebarPanel,
  },
  setup(props, { root }) {
    // 应用框架 必要组件 $can 权限判断
    const { $can } = root

    /* 接口调用，抽离参数，函数等 */
    const {
      moduleText,
      // 主事件控制区
      buttonLeftOptions,
      buttonRightOptions,
      mainActionEvent,
      // 表格呈现
      cellClassName,
      loading,
      tableData,
      xTable,
      // 表格操作菜单过滤
      setMenuDisabled,
      // 表格事件分发
      tableActionEvent,

      // 查询模块
      searchParams,
      searchParamsObject,
      //查询操作
      searhPanelButtonEvent,
      total,

      // 编辑对话框
      blankEditParams,
      editValue,
      editEvent,
      isShowSidebarActive,
    } = useFunction($can)

    return {
      moduleText,
      // 主事件控制区
      mainActionEvent,
      buttonLeftOptions,
      buttonRightOptions,

      // 表格呈现
      cellClassName,
      loading,
      tableData,
      xTable,
      // 表格操作菜单过滤
      setMenuDisabled,
      // 表格事件分发
      tableActionEvent,

      // 查询组件事件分发
      searhPanelButtonEvent,

      // 查询模块
      searchParams,
      searchParamsObject,
      total,

      // 编辑对话框
      blankEditParams,
      editValue,
      editEvent,
      isShowSidebarActive,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
