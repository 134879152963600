import request from '@/auth/jwt/request'

/**
 * @description 项目产品 -- 项目产品列表
 * @param {Object} param params {Object} 传值参数
 */
export const productListApi = params => { return request('project-product/list', 'get', params) }

/**
 * @description 项目政策 -- 项目下拉
 * @param {Object} param params {Object} 传值参数
 */
export const productProjetSelectApi = () => { return request('project/select', 'get', null) }

/**
 * @description 项目产品 -- 增加修改项目
 * id = 0 新增
 * @param {Object} param params {Object} 传值参数
 */
export const productSaveApi = data => { return request('project-product/save', 'post', {}, data) }

/**
 * @description 项目产品 -- 项目状态管理
 * @param {Object} param params {Object} 传值参数
 */
export const productStatusApi = params => { return request('project-product/status', 'get', params) }

/**
 * @description 项目产品 -- 项目详情
 * @param {Object} param params {Object} 传值参数
 */
export const productInfoApi = params => { return request('project-product/info', 'get', params) }
/**
 * @description 项目产品 -- 删除项目
 * { id = x }
 */
export const productDeleteApi = data => { return request('project-product/delete', 'delete', data) }