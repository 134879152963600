<template>
  <b-card no-body class="mb-1">
    <b-card-body class="pb-0 pt-1">
      <b-row>
        <b-col :cols="cols" class="flex-grow-1 mb-1" v-for="(item,index) in blankSearchObject" :key="index" v-if="!item.showMore">
          <b-form-group label-cols="auto" :label="item.label" style="margin-bottom: 0;">
            <template v-if="item.component === 'inputArray'">
              <b-row>
                <b-col>
                  <b-form-input
                    v-model="item.value[0]"
                    @update="(val) => setValue(val, index, 0)"
                    :id="item.param[0]"
                    :disabled="item.disabled ? item.disabled : false"
                    :form="item.form"
                    :autofocus="item.autofocus ? item.autofocus : false"
                    :size="item.size"
                    :type="item.type ? item.type : 'text'"
                    :trim="item.trim ? item.trim : true"
                    :autocomplete="item.autocomplete ? item.autocomplete : 'on'"
                    :placeholder="item.placeholder"
                    :formatter="item.formatter"
                    :number="item.number ? item.number : false"
                  />
                </b-col>
                <b-col class="inputCol-after">
                  <b-form-input
                    v-model="item.value[1]"
                    @update="(val) => setValue(val, index, 1)"
                    :id="item.param[1]"
                    :disabled="item.disabled ? item.disabled : false"
                    :form="item.form"
                    :autofocus="item.autofocus ? item.autofocus : false"
                    :size="item.size"
                    :type="item.type ? item.type : 'text'"
                    :trim="item.trim ? item.trim : true"
                    :autocomplete="item.autocomplete ? item.autocomplete : 'on'"
                    :placeholder="item.placeholder"
                    :formatter="item.formatter"
                    :number="item.number ? item.number : false"
                  />
                </b-col>
              </b-row>
            </template>
            <b-form-input
              v-if="item.component === 'input'"
              v-model="item.value"
              @update="(val) => setValue(val, index)"
              :id="item.param"
              :disabled="item.disabled ? item.disabled : false"
              :form="item.form"
              :autofocus="item.autofocus ? item.autofocus : false"
              :size="item.size"
              :type="item.type ? item.type : 'text'"
              :trim="item.trim ? item.trim : true"
              :autocomplete="item.autocomplete ? item.autocomplete : 'on'"
              :placeholder="item.placeholder"
              :formatter="item.formatter"
              :number="item.number ? item.number : false"
            />
            <!-- TODO: 未兼容v-select 全部属性 -->
            <v-select
              v-if="item.component === 'select'"
              v-model="item.value"
              class="w-100"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :label="item.selectLabel ? item.selectLabel : 'name'"
              :options="item.selectOptions"
              :multiple="item.multiple ? item.multiple : false"
              :reduce="val => item.valueTitle ? val[item.valueTitle]: val.id"
              @input="(val) => setValue(val, index)"
              :placeholder="item.placeholder"
            >
              <template v-slot:no-options>没有更多信息</template>
            </v-select>
          </b-form-group>
        </b-col>
        <!-- 渲染按钮 -->
        <b-col class="mb-1 flex-grow-1 d-flex justify-content-end">
          <b-button
              v-if="useSelectButton"
              variant="primary"
              class="search-btn"
              :class="(useResetButton || useMoreButton || useExportButton ) ? 'mr-50' : ''"
              @click="buttonEvent('search')"
            >
            <feather-icon icon="SearchIcon" class="mr-50" />
            <span>查询</span>
          </b-button>

          <b-button
            v-if="useResetButton"
            :class="(useMoreButton || useExportButton) ? 'mr-50' : ''"
            variant="outline-primary"
            class="btn-icon"
            @click="buttonEvent('reset')"
          >
            <feather-icon icon="RotateCcwIcon" />
            <!-- <span>重置</span> -->
          </b-button>

          <b-button
            v-if="useExportButton"
            class="btn-icon"
            :class="useMoreButton ? 'mr-50' : ''"
            variant="outline-success"
            @click="buttonEvent('export')"
          >
            <feather-icon icon="DownloadCloudIcon" />
            <!-- <span>导出</span> -->
          </b-button>

          <b-button
            v-if="useMoreButton"
            class="btn-icon"
            variant="flat-primary"
            @click="clickMore"
          >
            <feather-icon :icon="showMoreFlag ? 'ChevronsUpIcon': 'ChevronsDownIcon'" />
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { ref, watch, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    vSelect,
  },
  props: {
    searchParams: {
      type: Array,
      required: true,
    },
    useSelectButton: {
      type: [Boolean, null],
      default: true,
      required: false,
    },
    useResetButton: {
      type: [Boolean, null],
      default: true,
      required: false,
    },
    useExportButton: {
      type: [Boolean, null],
      default: false,
      required: false,
    },
  },

  setup(props, { emit }) {
    /* 必要组件加载区域 */

    // 判断是否包含需要展开显示的查询条件,如果存在需要展开显示的查询条件,显示展开按钮显示
    const useMoreButton = ref(false)
    for (const item of props.searchParams) {
      if (item.showMore && item.showMore === true) {
        useMoreButton.value = true
        break
      }
    }

    // 监听窗口变化, 从新设置断点的cols的值
    const cols = ref(6)
    // 设置监听函数
    // TODO: 未处理水平菜单情况下
    // 垂直情况下,判断窗口左侧菜单栏是否显示,如果是在显示状态下,减少260（菜单宽度为260px)
    const resizeForColsHandler = () => {
      let rightWidth = null
      if (window.innerWidth >= 1200) rightWidth = window.innerWidth - 260
      else rightWidth = window.innerWidth
      // 重新计算过右侧宽度后，重新设置断点
      switch (true) {
        case rightWidth >= 1200: cols.value = 3
          break
        case rightWidth >= 900: cols.value = 4
          break
        case rightWidth >= 600: cols.value = 6
          break
        default: cols.value = 12
      }
    }
    // 加载时候设置断点
    resizeForColsHandler()
    // 加载窗体的监听事件
    window.addEventListener('resize', resizeForColsHandler)
    // 组件注销时候,清除监听事件
    onUnmounted(() => { window.removeEventListener('resize', resizeForColsHandler) })

    // 制作响应式副本
    const blankSearchObject = ref(JSON.parse(JSON.stringify(props.searchParams)))

    // 标志现在面板是 全量 还是 简约 状态
    const showMoreFlag = ref(false)
    // 点击更多时候处理面板加载全部选择项
    const clickMore = () => {
      // 单前是展开状态
      if (showMoreFlag.value) {
        blankSearchObject.value = JSON.parse(JSON.stringify(props.searchParams))
        showMoreFlag.value = false
      } else { // 单前是隐藏状态
        blankSearchObject.value.forEach(el => {
          el.showMore = false
         })
        showMoreFlag.value = true
      }
    }

    /* 组件页面数据准备区域，包括表单，下拉选择项，数据整理操作等 */

    //提交参数数据回父组件
    const setValue = (val, index, flag = undefined) => {
      console.log("🚀 ~ file: index.vue ~ line 223 ~ setValue ~ val, index", val, index)
      emit('update:searchParams', val, index, flag) // flag: inputArray
    }

    //提交事件回调
    const buttonEvent = val => emit('button-event', val)

    // 更新组件参数
    watch(() => props.searchParams, () => {
      // 处理是否显示展开功能按钮
      for (const item of props.searchParams) {
        if (item.showMore && item.showMore === true) {
          useMoreButton.value = true
          break
        }
      }
      // 查询条件参数发生变化,更新对应的响应式副本
      blankSearchObject.value = JSON.parse(JSON.stringify(props.searchParams))
    })

    return {
      blankSearchObject,
      showMoreFlag,
      clickMore,

      buttonEvent,
      setValue,

      cols,
      useMoreButton,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.dropdown-width { width: 120px; }
.search-btn { min-width: 93px; }
.reset-btn { min-width: 73px; }

.inputCol-after.inputCol-after{
  position: relative;
  &::after{
    content: '';
    width: 10px;
    height: 2px;
    background: #d8d6de;
    position: absolute;
    left: -5px;
    top: calc(50% - 1px);
  }
}
</style>
